import {
  GetResourcesCommand,
  ResourceGroupsTaggingAPIClient,
  Tag,
} from "@aws-sdk/client-resource-groups-tagging-api";
import type { Project } from "./projects";

type AwsResource = { arn: string; tags: Tag[] };
export type AwsResourcesByType = {
  loadBalancers: AwsResource[];
  ecsClusters: AwsResource[];
  ecsServices: AwsResource[];
  dbInstances: AwsResource[];
};

export const assignAwsResourcesToProjects = (
  projects: Project[],
  awsResources: AwsResourcesByType,
) => {
  for (const project of projects) {
    for (const type of Object.keys(
      awsResources,
    ) as (keyof AwsResourcesByType)[]) {
      project[type] = awsResources[type].filter((resource) =>
        resource.tags.some(
          (tag) => tag.Key === "Repo" && tag.Value === project["AWS Repo tag"],
        ),
      );
    }
  }
};

export const fetchAwsResources = async (): Promise<AwsResourcesByType> => {
  const client = new ResourceGroupsTaggingAPIClient({
    region: "eu-north-1",
    credentials: {
      accessKeyId: process.env.AWS_ACCESS_KEY_ID!,
      secretAccessKey: process.env.AWS_SECRET_ACCESS_KEY!,
    },
  });

  const loadBalancers: AwsResource[] = [],
    ecsClusters: AwsResource[] = [],
    ecsServices: AwsResource[] = [],
    dbInstances: AwsResource[] = [];

  let paginationToken: string | undefined = undefined;
  do {
    const command: GetResourcesCommand = new GetResourcesCommand({
      ResourceTypeFilters: [
        "ecs:cluster",
        "ecs:service",
        "rds:db",
        "elasticloadbalancing:loadbalancer",
      ],
      TagFilters: [
        {
          Key: "Pulumi",
          Values: ["true"],
        },
      ],
      ResourcesPerPage: 100,
      PaginationToken: paginationToken,
    });
    const response = await client.send(command);
    paginationToken = response.PaginationToken;

    for (const resource of response.ResourceTagMappingList || []) {
      const resourceData = { arn: resource.ResourceARN!, tags: resource.Tags! };
      if (resourceData.arn?.includes(":loadbalancer/")) {
        loadBalancers.push(resourceData);
      } else if (resourceData.arn?.includes(":cluster/")) {
        ecsClusters.push(resourceData);
      } else if (resourceData.arn?.includes(":service/")) {
        ecsServices.push(resourceData);
      } else if (resourceData.arn?.includes(":db:")) {
        dbInstances.push(resourceData);
      }
    }
  } while (paginationToken);
  return { loadBalancers, ecsClusters, ecsServices, dbInstances };
};
