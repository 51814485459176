import {
  EmbeddedScene,
  SceneControlsSpacer,
  SceneDataLayerSet,
  SceneFlexLayout,
  SceneRefreshPicker,
  SceneTimePicker,
  SceneTimeRange,
  SceneVariableSet,
  VariableValueSelectors,
} from "@grafana/scenes";
import getEcsRuntimeCpuUsagePanels from "./components/Panels/cloudwatch/ecs";
import getRdsRuntimeCpuUsagePanels from "./components/Panels/cloudwatch/rds";
import getSentryPanels from "./components/Panels/sentry";
import getElbPanels from "./components/Panels/cloudwatch/elb";
import { getAnnotationsDataLayers } from "./components/Annotations/pingdom";
import { Project } from "./metadata/projects";
import { Datasources } from "./datasources";

export const getProjectScene = (project: Project, datasources: Datasources) => {
  const timeRange = new SceneTimeRange({
    from: "now-6h",
    to: "now",
  });

  const loadBalancerNames =
    (project.loadBalancers || []).map(({ arn }) =>
      arn.substring(arn.indexOf("/") + 1),
    ) || [];

  const rdsInstanceNames =
    (project.dbInstances || []).map(({ arn }) => arn.split(":").pop()!) || [];

  const clusterNamesWithServices = (
    project.ecsClusters?.map(({ arn }) =>
      arn.substring(arn.indexOf("/") + 1),
    ) || []
  ).map(
    (clusterName) => ({
      clusterName,
      ecsServices: (project.ecsServices || [])
        .filter(({ arn }) => arn.split("/").includes(clusterName))
        .map(({ arn }) => arn.split("/").pop()!),
    }),
    [],
  );

  return new EmbeddedScene({
    $timeRange: timeRange,
    $variables: new SceneVariableSet({ variables: [] }),
    body: new SceneFlexLayout({
      $data: new SceneDataLayerSet({
        layers: getAnnotationsDataLayers(datasources.Pingdom, project),
      }),
      children: [
        ...getSentryPanels(datasources.Sentry, project),
        ...getEcsRuntimeCpuUsagePanels(
          datasources.CloudWatch,
          clusterNamesWithServices,
        ),
        ...getRdsRuntimeCpuUsagePanels(
          datasources.CloudWatch,
          rdsInstanceNames,
        ),
        ...getElbPanels(datasources.CloudWatch, loadBalancerNames),
      ],
    }),
    controls: [
      new VariableValueSelectors({}),
      new SceneControlsSpacer(),
      new SceneTimePicker({ isOnCanvas: true }),
      new SceneRefreshPicker({
        intervals: ["5s", "1m", "1h"],
        isOnCanvas: true,
      }),
    ],
  });
};
