import React, { useEffect, useMemo, useState } from "react";
import { SceneApp, SceneAppPage } from "@grafana/scenes";
import { prefixRoute, ROUTES } from "../../constants";
import { getProjectScene as getProjectScene } from "../../scenes";
import { css } from "@emotion/css";
import { useStyles2 } from "@grafana/ui";
import { fetchProjects, Project } from "metadata/projects";
import { assignAwsResourcesToProjects, fetchAwsResources } from "metadata/aws";
import { Datasources, getDataSources } from "datasources";
import {
  assignPingdomChecksToProjects,
  fetchPingdomChecks,
} from "metadata/pingdom";

const getScene = (projects: Project[], datasources: Datasources | undefined) =>
  new SceneApp({
    pages: !datasources
      ? []
      : [
          new SceneAppPage({
            title: "Uptime dashboard",
            // Important: Mind the page route is ambiguous for the tabs to work properly
            url: prefixRoute(ROUTES.Home),
            hideFromBreadcrumbs: true,
            preserveUrlKeys: ["from", "to"],
            tabs: projects.map(
              (project) =>
                new SceneAppPage({
                  title: project["User-friendly name"],
                  url: prefixRoute(
                    `${ROUTES.Home}/${project["User-friendly name"].replaceAll(" ", "-")}`,
                  ),
                  preserveUrlKeys: ["from", "to"],
                  getScene: () => getProjectScene(project, datasources),
                }),
            ),
          }),
        ],
  });

export const HomePage = () => {
  const s = useStyles2(getStyles);

  const [projects, setProjects] = useState<Project[]>([]);
  const [datasources, setDatasources] = useState<Datasources>();

  useEffect(() => {
    (async () => {
      const newDatasources = await getDataSources();
      setDatasources(newDatasources);

      const newProjects = await fetchProjects(
        newDatasources["FirstVet projects metadata"],
      );

      assignAwsResourcesToProjects(newProjects, await fetchAwsResources());
      assignPingdomChecksToProjects(
        newProjects,
        await fetchPingdomChecks(newDatasources.Pingdom),
      );
      setProjects(newProjects);
    })();
  }, []);

  const scene = useMemo(
    () => getScene(projects, datasources),
    [projects, datasources],
  );

  return (
    <div className={s.container}>
      <scene.Component model={scene} />
    </div>
  );
};

const getStyles = () => ({
  container: css`
    display: flex;
    flex-grow: 1;
    height: 100%;
  `,
});
