import {
  FieldConfigOverridesBuilder,
  PanelBuilders,
  SceneDataProvider,
  SceneDataQuery,
  SceneDataState,
  SceneFlexItem,
} from "@grafana/scenes";
import { FieldColorModeId, FieldConfig } from "@grafana/data";
import {
  GraphDrawStyle,
  StackingMode,
  TooltipDisplayMode,
  SortOrder,
  LegendDisplayMode,
} from "@grafana/schema";
import { Datasources } from "datasources";
import { createPanelGroup } from "../PanelGroup";

const metrics = {
  HTTPCode_ELB_5XX_Count: { refId: "5xx", color: "dark-purple" },
  HTTPCode_Target_5XX_Count: { refId: "5xx_targets", color: "purple" },
  HTTPCode_ELB_4XX_Count: { refId: "4xx", color: "dark-red" },
  HTTPCode_Target_4XX_Count: { refId: "4xx_targets", color: "red" },
  HTTPCode_ELB_3XX_Count: { refId: "3xx", color: "dark-blue" },
  HTTPCode_Target_3XX_Count: { refId: "3xx_targets", color: "blue" },
  HTTPCode_Target_2XX_Count: { refId: "2xx", color: "semi-dark-green" },
};

const getMetricColorOverrides = (b: FieldConfigOverridesBuilder<FieldConfig>) =>
  Object.entries(metrics).reduce(
    (b, [metricName, { color }]) =>
      b.matchFieldsWithName(metricName).overrideColor({
        mode: FieldColorModeId.Fixed,
        fixedColor: color,
      }),
    b,
  );

export default (
  datasource: Datasources["CloudWatch"],
  loadBalancerNames: string[],
) => {
  const getQueriesForFilter = ({
    loadBalancerName,
  }: {
    loadBalancerName: string;
  }): SceneDataQuery[] =>
    Object.entries(metrics).map(([metricName, { refId }]) => ({
      datasource,
      dimensions: {
        LoadBalancer: loadBalancerName,
      },
      expression: "",
      hide: false,
      id: "",
      label: "",
      logGroups: [],
      matchExact: true,
      metricEditorMode: 0,
      metricName,
      metricQueryType: 0,
      namespace: "AWS/ApplicationELB",
      period: "",
      queryMode: "Metrics",
      refId,
      region: "eu-north-1",
      sqlExpression: "",
      statistic: "Sum",
    }));

  const getPanels = (data: SceneDataProvider<SceneDataState>[]) =>
    data.map(
      (panelData, index) =>
        new SceneFlexItem({
          minHeight: 300,
          $data: panelData,
          body: PanelBuilders.timeseries()
            // Title is using variable value
            .setTitle(
              `Request count per status code (${loadBalancerNames![index]})`,
            )
            .setCustomFieldConfig("drawStyle", GraphDrawStyle.Bars)
            .setCustomFieldConfig("fillOpacity", 100)
            .setCustomFieldConfig("stacking", {
              mode: StackingMode.Normal,
            })
            .setOption("tooltip", {
              mode: TooltipDisplayMode.Multi,
              sort: SortOrder.Descending,
            })
            .setOption("legend", {
              showLegend: true,
              displayMode: LegendDisplayMode.List,
            })
            .setOverrides(getMetricColorOverrides)
            .build(),
        }),
    );

  const queryFilters = loadBalancerNames.map((loadBalancerName) => ({
    loadBalancerName,
  }));
  return createPanelGroup({
    datasource,
    queryFilters,
    getQueriesForFilter,
    getPanels,
  });
};
