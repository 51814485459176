import { getBackendSrv } from "@grafana/runtime";
import { lastValueFrom } from "rxjs";

export type DataSource = {
  id: number;
  type: string;
  uid: string;
};
export type Datasources = {
  "FirstVet projects metadata": DataSource;
  Sentry: DataSource;
  Pingdom: DataSource;
  CloudWatch: DataSource;
};
type GetDataSourcesResponse = (DataSource & { name: string })[];
export const getDataSources = (): Promise<Datasources> =>
  lastValueFrom(
    getBackendSrv().fetch<GetDataSourcesResponse>({
      url: "/api/datasources",
      method: "GET",
    }),
  ).then(({ data }) =>
    data.reduce(
      (acc, ds) => ({
        ...acc,
        [ds.name]: ds,
      }),
      {} as Datasources,
    ),
  );
