import {
  SceneDataProvider,
  SceneDataQuery,
  SceneDataTransformer,
  SceneDataTransformerState,
  SceneFlexItem,
  SceneQueryRunner,
} from "@grafana/scenes";
import { DataSource } from "../../datasources";

export const createPanelGroup = <QueryFilter extends object | undefined>({
  datasource,
  queryFilters,
  getQueriesForFilter,
  getPanels,
  transformations,
}: {
  datasource: DataSource;
  queryFilters: QueryFilter[];
  getPanels: (data: SceneDataProvider[]) => SceneFlexItem[];
  getQueriesForFilter: (filter: QueryFilter) => SceneDataQuery[];
  transformations?: SceneDataTransformerState["transformations"];
}) => {
  const queryRunners = (queryFilters || [{}]).map(
    (filter) =>
      new SceneQueryRunner({
        datasource: datasource,
        queries: getQueriesForFilter(filter as QueryFilter),
        maxDataPoints: 100,
      }),
  );

  let transformedRunners;
  if (transformations?.length) {
    transformedRunners = queryRunners.map(
      (queryRunner) =>
        new SceneDataTransformer({
          $data: queryRunner,
          transformations,
        }),
    );
  }

  return getPanels(transformedRunners || queryRunners);
};
