import { getBackendSrv } from "@grafana/runtime";
import { Datasources } from "datasources";
import { lastValueFrom } from "rxjs";
import { Project } from "./projects";

type PingdomChecksResponse = {
  results: {
    Pingdom_checks: {
      status: number;
      frames: {
        data: { values: [string[]] };
      }[];
    };
  };
};

const fetchFromPingdom = (
  datasource: Datasources["Pingdom"],
  checkId?: number,
) =>
  lastValueFrom(
    getBackendSrv().fetch<PingdomChecksResponse>({
      url: `/api/ds/query?ds_type=yesoreyeram-infinity-datasource`,
      method: "POST",
      data: {
        queries: [
          {
            mappings: {
              tags: {
                source: "field",
                value: "type",
              },
            },
            columns: [],
            datasource: datasource,
            datasourceId: datasource.id,
            filters: [],
            format: "table",
            global_query_id: "",
            intervalMs: 20000,
            maxDataPoints: 1226,
            parser: "backend",
            refId: "Pingdom_checks",
            root_selector: checkId
              ? '"https://" & check.hostname & check.type.http.url'
              : "checks.id",
            source: "url",
            type: "json",
            url: `https://api.pingdom.com/api/3.1/checks${checkId ? `/${checkId}` : ""}`,
            url_options: {
              data: "",
              method: "GET",
            },
          },
        ],
        to: "1728560400972",
      },
    }),
  ).then(({ data }) => data.results.Pingdom_checks.frames[0].data.values[0]);

export const fetchPingdomChecks = async (
  datasource: Datasources["Pingdom"],
): Promise<Record<string, number>> =>
  fetchFromPingdom(datasource)
    .then((checks) =>
      Promise.all(
        checks.map((id) =>
          fetchFromPingdom(datasource, parseInt(id)).then(([hostname]) => [
            hostname,
            id,
          ]),
        ),
      ),
    )
    .then(Object.fromEntries);

export const assignPingdomChecksToProjects = (
  projects: Project[],
  pingdomChecks: { [hostname: string]: number },
) => {
  for (const project of projects.filter(
    ({ "Pingdom URL check regex": regex }) => regex,
  )) {
    project.pingdomChecks = Object.entries(pingdomChecks)
      .filter(([hostname]) =>
        new RegExp(project["Pingdom URL check regex"]!).test(hostname),
      )
      .map(([hostname, checkId]) => ({ hostname, checkId }));
  }
};
