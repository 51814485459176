import { getBackendSrv } from "@grafana/runtime";
import { lastValueFrom } from "rxjs";
import type { AwsResourcesByType } from "./aws";
import { Datasources } from "../datasources";

export type Project = {
  "AWS Repo tag"?: string;
  "Sentry project IDs"?: { [name: string]: number };
  "User-friendly name": string;
  "Pingdom URL check regex"?: string;
} & {
  pingdomChecks?: { hostname: string; checkId: number }[];
} & Partial<AwsResourcesByType>;

type ProjectResponseRaw = {
  results: {
    A: { frames: [{ schema: { meta: { custom: { data: string } } } }] };
  };
};

export const fetchProjects = async (
  datasource: Datasources["FirstVet projects metadata"],
): Promise<Project[]> =>
  lastValueFrom(
    getBackendSrv().fetch<ProjectResponseRaw>({
      url: "/api/ds/query?ds_type=yesoreyeram-infinity-datasource&requestId=explore_s8b",
      method: "POST",
      data: {
        from: "1727169166011",
        to: "1727169166011",
        queries: [
          {
            refId: "A",
            datasource,
            type: "json",
            source: "reference",
            format: "table",
            url: "",
            url_options: {
              method: "GET",
              data: "",
            },
            root_selector: "",
            filters: [],
            global_query_id: "",
            parser: "backend",
            referenceName: "Projects",
            computed_columns: [],
            filterExpression: "",
            summarizeExpression: "",
            datasourceId: datasource.id,
            intervalMs: 2000,
            maxDataPoints: 1712,
          },
        ],
      },
    }),
  ).then((results) =>
    JSON.parse(results.data.results.A.frames[0].schema.meta.custom.data),
  );
