import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { HomePage } from "../../pages/Home";

import { prefixRoute, ROUTES } from "../../constants";

export const Routes = () => (
  <Switch>
    <Route path={prefixRoute(ROUTES.Home)} component={HomePage} />

    <Redirect to={prefixRoute(ROUTES.Home)} />
  </Switch>
);
