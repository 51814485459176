import { dataLayers } from "@grafana/scenes";
import { Datasources } from "datasources";
import { Project } from "metadata/projects";

export const getAnnotationsDataLayers = (
  datasource: Datasources["Pingdom"],
  project: Project,
) =>
  (project.pingdomChecks || []).map(
    ({ hostname, checkId }) =>
      new dataLayers.AnnotationsDataLayer({
        name: "Global annotations",
        query: {
          datasource,
          enable: true,
          iconColor: "red",
          name: "Pingdom downtimes",
          target: {
            // @ts-expect-error Dynamic properties
            filters: [],
            format: "table",
            global_query_id: "",
            parser: "backend",
            refId: "Pingdom downtimes",
            root_selector: 'summary.states[status = "down"]',
            source: "url",
            tags: [],
            type: "json",
            url: `https://api.pingdom.com/api/3.1/summary.outage/${checkId}`,
            url_options: {
              data: "",
              method: "GET",
              params: [
                {
                  key: "from",
                  value: String(
                    Math.round(new Date().getTime() / 1000) - 3600 * 24 * 30,
                  ),
                },
              ],
            },
            datasource,
            datasourceId: datasource.id,

            columns: [
              {
                selector: "timefrom",
                text: "time",
                type: "timestamp_epoch_s",
              },
              {
                selector: "timeto",
                text: "timeEnd",
                type: "timestamp_epoch_s",
              },
            ],
            computed_columns: [
              {
                selector: `"${hostname}"`,
                text: "text",
                type: "string",
              },
            ],
          },
        },
      }),
  );
